'use strict';

angular.module('ufcw')
  .controller('HorariosController', function ($log,Request,$localstorage,$state,$rootScope,EnvConfig) {
    var vm = this;
    vm.errorMsg = null;
    
    $rootScope.headLander = false;

    var token  = $localstorage.get('token');
    var user   = $localstorage.getObject('user');
    vm.backPath = "/home";
    if(!token){
      vm.backPath = "/signin";
    }
    if(user.userAccess === "CANCHA"){
      vm.backPath = "/homecanchas";
    }
    
    //get activities for user category
    Request.get('nooauthactivity/get/?type=cat',true,function(response) {
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
        });
    });
      

  
  });

  



