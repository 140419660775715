'use strict';

angular.module("ufcw")

.config(function ($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.otherwise('/signin');

    $stateProvider
      .state('signin', {
        url: '/signin',
        templateUrl: '../views/signin.html',
        controller: 'SigninController as signinCtrl'
      })
      .state('home', {
        url: '/home',
        templateUrl: '../views/home.html',
        controller: 'HomeController as homeCtrl'
      })
      .state('reservaactividad/:id', {
        url: '/reservaactividad',
        templateUrl: '../views/reservaactividad.html',
        controller: 'ReservaactividadController as raCtrl'
      })
      .state('reservaactividadcustom', {
        url: '/reservaactividadcustom',
        params: {activity: null},
        templateUrl: '../views/reservaactividadcustom.html',
        controller: 'ReservaactividadcustomController as raCtrl'
      })
      .state('signup', {
        url: '/signup',
        templateUrl: '../views/signup.html',
        controller: 'SignupController as signupCtrl'
      })
      .state('welcome/:token', {
        url: '/welcome',
        templateUrl: '../views/welcome.html',
        controller: 'WelcomeController as welcomeCtrl'
      })
      .state('recovery', {
        url: '/recovery',
        templateUrl: '../views/recovery.html',
        controller: 'RecoveryController as recoveryCtrl'
      })
      .state('setpwd', {
        url: '/setpwd',
        templateUrl: '../views/setpwd.html',
        controller: 'RecoveryController as recoveryCtrl'
      })
      .state('misreservas', {
        url: '/misreservas',
        templateUrl: '../views/misreservas.html',
        controller: 'MisreservasController as mrCtrl'
      })
      .state('historialreservas', {
        url: '/historialreservas',
        templateUrl: '../views/historialreservas.html',
        controller: 'HistorialreservasController as hrCtrl'
      })
      .state('waitinglist', {
        url: '/waitinglist',
        templateUrl: '../views/waitinglist.html',
        controller: 'WaitinglistController as wlCtrl'
      })
      .state('myprofile', {
        url: '/myprofile',
        templateUrl: '../views/myprofile.html',
        controller: 'MyProfileController as mpCtrl'
      })
      .state('notice', {
        url: '/notice',
        templateUrl: '../views/notice.html',
        controller: 'NoticeController as ntcCtrl'
      })
      .state('horarios', {
        url: '/horarios',
        templateUrl: '../views/horarios.html',
        controller: 'HorariosController as fcCtrl'
      })
      .state('fronthorarios/:id', {
        url: '/fronthorarios',
        templateUrl: '../views/fronthorarios.html',
        controller: 'FronthorariosController as fhCtrl'
      })
      .state('reglas', {
        url: '/reglas',
        templateUrl: '../views/reglas.html',
        controller: 'ReglasController as reCtrl'
      })
      .state('message', {
        url: '/message',
        templateUrl: '../views/message.html',
        controller: 'MessageController as msgCtrl'
      })
      .state('advancedsearch', {
        url: '/advancedsearch',
        templateUrl: '../views/advancedsearch.html',
        controller: 'AdvancedsearchController as asCtrl'
      })                
      .state('qr', {
        url: '/qr',
        templateUrl: '../views/qr.html',
        controller: 'QrController as qrCtrl'
      })   
      .state('cuponeras/:id', {
        url: '/cuponeras',
        templateUrl: '../views/cuponeras.html',
        controller: 'CuponeraController as cpCtrl'
      })     
      .state('comprarcuponera', {
        url: '/comprarcuponera',
        templateUrl: '../views/comprarcuponera.html',
        controller: 'ComprarcuponeraController as ccpCtrl'
      })    
      .state('fixedactivities', {
        url: '/fixedactivities',
        templateUrl: '../views/fixedactivities.html',
        controller: 'FixedactivitiesController as fxCtrl'
      })
      .state('homecanchas', {
        url: '/homecanchas',
        templateUrl: '../views/homecanchas.html',
        controller: 'HomeCanchaController as homecanCtrl'
      })       
      .state('cancha/:id', {
        url: '/cancha',
        templateUrl: '../views/canchas.html',
        controller: 'CanchaController as chaCtrl'
      })          
      .state('reservacancha/:id', {
        url: '/reservacancha',
        templateUrl: '../views/reservacancha.html',
        controller: 'ResCanchaController as rescanCtrl'                
      })  
      .state('routine', {
        url: '/routine',
        templateUrl: '../views/routine.html',
        controller: 'RoutineController as rtCtrl'
      })        
      .state('map', {
        url: '/map',
        templateUrl: '../views/map.html',
        controller: 'MapController as rtCtrl'
      })        
  })
  .config(function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
})
.config(['$locationProvider', function($locationProvider) {
  $locationProvider.html5Mode(true);
  $locationProvider.hashPrefix('');
}])
.run(["$locale", function ($locale) {
  $locale.NUMBER_FORMATS.GROUP_SEP = ".";
  $locale.NUMBER_FORMATS.DECIMAL_SEP = ",";
}])
.config(['$translateProvider', function($translateProvider) {
  $translateProvider.translations('en', en_lang);
  $translateProvider.translations('es', es_lang);
  $translateProvider.preferredLanguage('es');
  $translateProvider.useSanitizeValueStrategy('escape')

}]);
