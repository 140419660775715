'use strict';

angular.module('ufcw')
  .controller('FronthorariosController', function ($log,Request,$rootScope,$location,toaster,$translate) {
    var vm = this;
    vm.errorMsg = null;
    var id = $location.search().id; 
    
    $rootScope.headLander = false;
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }



    function UniqueArraybyhour(collection, keyname) {
      var output = []; 
        var  keys = [];

        var aux_list_field = Object.keys(collection);
        // $log.log(aux_list_field,'pino');
  
      angular.forEach(aux_list_field, function(itemWeek) {
        // $log.log(itemWeek);
        angular.forEach(collection[itemWeek], function(item) {
          var key = item[keyname];
         
          if(keys.indexOf(key) === -1) {
              keys.push(key);
              var newHour = {};
              newHour.time =item.starttime;

              if  (item.starttime<'12:00:00'){
                newHour.turno ='#3abba9';
                newHour.text = 'white';
              }else{
                if  (item.starttime<'16:00:00'){
                  newHour.turno ='#3095bb';
                  newHour.text = 'white';
                }else{
                  if  (item.starttime<'19:00:00'){
                    newHour.turno ='#164d7b';
                    newHour.text = 'white';
                  }else{
                    newHour.turno ='#082b49';
                    newHour.text = 'white';
                  }
                  
                }
  
              }
              newHour.days = vm.createEmptyArrayDay();

  
              output.push(newHour);
          }
      });
      // $log.log(output,'output');

    });
  
      output.sort(function(a, b) {
        return a.time.localeCompare(b.time);
      });
  return output;
  }


  vm.findWithAttr=function(array, attr, value) {
 
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
};

    vm.load = function(id){
      //get activities for user category
      if(id){
        Request.get('nooauthactivity/?type=act&id='+id,true,function(response) {
          if(response.status !== 0){
            toaster.pop('info', "", $translate.instant('api.'+response));
          }else{
            var categories = [];
            for (var day in response.description) {
              for (var acts in response.description[day]) {
                categories.push(response.description[day][acts].UserCategory);
              }
            }
            vm.activities = response.description;
            vm.ucategories = categories.filter(unique);
            vm.ucategories.sort();
            
            vm.show_select = (vm.ucategories.length>1)?true:false;
            vm.selCategory = "";
  
            vm.arrayHour = UniqueArraybyhour(vm.activities, "starttime");
  
            
            var auxKeys = Object.keys(vm.activities);
            
            angular.forEach(auxKeys, function(mItemk){
              // $log.log(mItemk);
              angular.forEach( vm.activities[mItemk], function(mItem){
                // $log.log(mItem,'coco');
                // $log.log(vm.arrayHour);
                var hourPosition = vm.findWithAttr(vm.arrayHour,'time',mItem.starttime);
  
                if (hourPosition>=0){
  
                  var dayPosition = vm.findWithAttr(vm.arrayHour[hourPosition].days,'dofw',mItem.dayoftheweek);
  
                  
                  if (dayPosition>=0){
                    vm.arrayHour[hourPosition].days[dayPosition].list_at.push(mItem);
                  }
  
  
                }
              });
  
            });
            
          } 
        });
      }
    };

    vm.loadActivities = function (){
      Request.get('nooauthactivity/get/?type=cat',true,function(response) {
        vm.listactivities = response.description;
        vm.listactivities.sort();
        vm.show_selectAct = (vm.listactivities.length>1)?true:false;
      });
    };

    vm.createEmptyArrayDay=function(){
      var m_Array = [];
      var ix ;
      for ( ix = 0; ix < 7; ix++) {
        var m_item = {};
        m_item.dofw = ix+1;
  
        if (ix===6){
          m_item.dofw=0;
        }
       var nameday = "";
  
        switch (m_item.dofw) {
          case 0:
            nameday = $translate.instant("days.sunday");
            break;
          case 1:
            nameday = $translate.instant("days.monday");
            break;
          case 2:
            nameday = $translate.instant("days.tuesday");
            break;
          case 3:
            nameday = $translate.instant("days.wednesday");
            break;
          case 4:
            nameday = $translate.instant("days.thursday");
            break;
          case 5:
            nameday = $translate.instant("days.friday");
            break;
          case 6:
            nameday = $translate.instant("days.saturday");
            break;
        }
        m_item.nameDay = nameday;
        m_item.list_at =[];
        m_Array.push(m_item);
      }

      return m_Array;
  
      
    };




    
    vm.getnameday=function(codday){
      var nameday = "";
      switch (codday) {
        case 0:
          nameday = $translate.instant('days.sunday');
          break;
        case 1:
          nameday = $translate.instant('days.monday');
          break;
        case 2:
          nameday = $translate.instant('days.tuesday');
          break;
        case 3:
          nameday = $translate.instant('days.wednesday');
          break;
        case 4:
          nameday = $translate.instant('days.thursday');
          break;
        case 5:
          nameday = $translate.instant('days.friday');
          break;
        case 6:
          nameday = $translate.instant('days.saturday');
          break;
    
        default:
          break;
      }
      return nameday;
    };

    vm.load(id);
    vm.loadActivities();

  });

  



