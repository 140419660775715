'use strict';

angular.module('ufcw')
  .controller('HistorialreservasController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,$translate) {
    var vm = this;
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');

    vm.users = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.users.length === 0)?false:true;
    vm.users.push(user);

    vm.from_date = new Date();
    vm.from_date.setDate(vm.from_date.getDate() - 7);
    vm.to_date   = new Date();

    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant('reserve.history');

    var STATUS_MAP = {
      RESERVED: $translate.instant('reserve.status_reserved'),
      CANCHARESERVED: $translate.instant('reserve.status_reserved'),
      CUPONERARESERVED: $translate.instant('reserve.status_reserved'),
      CANCELED: $translate.instant('reserve.status_canceled'),
      CANCHACANCELED: $translate.instant('reserve.status_canceled'),
      CUPONERACANCELED: $translate.instant('reserve.status_canceled'),
      null: '',
      '': '',
      undefined: '',
    };

    var ATTENDANCE_MAP = {
      1: $translate.instant('general.yes'),
      0: $translate.instant('general.no'),
      null: '-',
      '': '',
      undefined: '',
    };

    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];

    vm.load = function(){
      var params = "?from=2022-07-01&to=2023-01-01";
      var from = "";
      var to   = "";
      if(vm.from_date && vm.to_date){
        if(vm.from_date > vm.to_date){
          toaster.pop('info', "", $translate.instant('La fecha DESDE no puede ser mayor a la fecha HASTA'));
          return;
        }
        from = vm.from_date.getFullYear() + "-" + (vm.from_date.getMonth()+1) + "-" + vm.from_date.getDate();
        to = vm.to_date.getFullYear() + "-" + (vm.to_date.getMonth()+1) + "-" + vm.to_date.getDate();
        params = "?from="+from+"&to="+to;
      }

      Request.get('reservation/'+params,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.reservas = response.description;
          vm.reservas.map(function(item){
            item.status = STATUS_MAP[item.status];
            item.attendance = ATTENDANCE_MAP[item.attendance];
            item.reservationdate = item.reservationdate.replace(/-/g, '/');
            var d = new Date(item.reservationdate);
            
            var dayName = days[d.getDay()];
            var dayNumber = d.getDate();
            var monthName = months[d.getMonth()];
            var year = d.getFullYear();
            item.day = dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName +" "+year;

            var hours = d.getHours().toString(); 
            var minutes = d.getMinutes().toString(); 
            hours = (hours.length===1)?"0"+hours:hours;
            minutes = (minutes.length===1)?minutes+"0":minutes;
            item.start_custom = hours + ":" + minutes;
          });
          vm.no_reservations = (vm.reservas.length === 0)?true:false;
        }
      });
    };

    vm.load();

  //DATETIMEPICKERS

  function getDayClass(data) {
    var date = data.date,
      mode = data.mode;
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0,0,0,0);

      for (var i = 0; i < vm.events.length; i++) {
        var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

        if (dayToCheck === currentDay) {
          return vm.events[i].status;
        }
      }
    }

    return '';
  }

  vm.today = function() {
    vm.dt = new Date();
  };
  vm.today();

  vm.clear = function() {
    vm.dt = null;
  };

  vm.inlineOptions = {
    customClass: getDayClass,
    minDate: new Date(),
    showWeeks: true
  };

  // Disable weekend selection
  function disabled(data) {
    var date = data.date,
      mode = data.mode;
    return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
  }

  vm.dateOptions = {
    dateDisabled: disabled,
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    minDate: new Date(),
    startingDay: 1
  };

  vm.toggleMin = function() {
    vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
    vm.dateOptions.minDate = vm.inlineOptions.minDate;
  };

  vm.toggleMin();

  vm.open1 = function() {
    vm.popup1.opened = true;
  };

  vm.open2 = function() {
    vm.popup2.opened = true;
  };

  vm.setDate = function(year, month, day) {
    vm.dt = new Date(year, month, day);
  };

  vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  vm.format = vm.formats[0];
  vm.altInputFormats = ['M!/d!/yyyy'];

  vm.popup1 = {
    opened: false
  };

  vm.popup2 = {
    opened: false
  };

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  var afterTomorrow = new Date();
  afterTomorrow.setDate(tomorrow.getDate() + 1);
  vm.events = [
    {
      date: tomorrow,
      status: 'full'
    },
    {
      date: afterTomorrow,
      status: 'partially'
    }
  ];

  vm.openGuests = function (size, parentSelector) {
    var parentElem = parentSelector ? 
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    var modalInstance = $uibModal.open({
      animation: vm.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'guests.html',
      controller: 'ModalEditGuestsController',
      controllerAs: 'modalCtrl',
      size: size,
      backdrop: 'static',
      keyboard: false,
      appendTo: parentElem,
      resolve: {
        activity: function () {
          return vm.activity;
        }
      }
    });

    modalInstance.result.then(function () {
    }, function () {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  };

  vm.showGuests = function(activity){
    if(activity.allowGuest ===1 && angular.isString(activity.description)){
      activity.guests = activity.description.split(',');
      delete activity.description;
    }
    vm.activity = activity;
    vm.openGuests();
  };

});
