'use strict';

angular.module('ufcw')
  .controller('CuponeraController', function ($rootScope,$log,$location,Request,$localstorage,$uibModal,$document,$state,toaster,$translate) {
    
    var vm = this;
    vm.reservation_enabled = true;
    vm.carnetExpirated = false;
    vm.payExpirated = false;
    vm.payment_text = "";
    

    // var id = $location.search().id; 
    var user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    $rootScope.user = user;
    vm.showNextDay = true;

    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("Actividades cuponera");
   
    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    var d = new Date();
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() { });

    };
    
    vm.daycount = 0;

    vm.changeDate = function(option){
      if(option==='ADD'){
        vm.daycount+=1;
        d.setDate(d.getDate() + 1);
      }else{
        vm.daycount-=1;
        d.setDate(d.getDate() - 1);
      }
      if(vm.daycount>0){
        vm.showNextDay = false;
      }else if(vm.daycount===0){
        vm.showNextDay = true;
      }
      
        var cuponeraExpiration = new Date(vm.infocuponera.expirationDate);
        if(d<=cuponeraExpiration){
          
                    vm.showNextDay = true;

        }else{
          vm.showNextDay = false;
        }


    };

    vm.load = function(){
      var id = $location.search().id; 

      Request.get('usercuponera/?id='+id+"&detail=true",true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          
          vm.infocuponera = response.description.cuponera[0];
          // vm.infocuponera.created = vm.infocuponera.created.replace(/(.+) (.+)/, "$1T$2Z");
          // vm.infocuponera.created = new Date(vm.infocuponera.created).getTime();
          vm.infocuponera.created = (vm.infocuponera.created)? new Date(vm.infocuponera.created):vm.infocuponera.created;
          vm.cuponera_detail = {}; 
          vm.cuponera_detail.reservations = response.description.reservations;
          vm.cuponera_detail.title = response.description.cuponera[0];
        }
      });


      var dayName = days[d.getDay()];
      var dayNumber = d.getDate();

      var monthName = months[d.getMonth()];
      
      vm.day=dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName;

      var params = "?id="+id+"&days="+vm.daycount+"&userId="+$rootScope.user.id;

      //cambiar a cuponeraactividad
      Request.get('cuponeraactivity/'+params,true,function(response) {
        if(response.status !== 0){
          vm.errorMsg = $translate.instant(response.description);
        }else{
          // $localstorage.setObject('config',response.config);
          // var config  = $localstorage.getObject('config');
          // if(config.reservationEnabled===0){
          //   vm.reservation_enabled = false;
          //   vm.notice_title = config.noticeAlertTitle;
          //   vm.notice_text = config.noticeAlertText;
          // }

          vm.activities = response.description;
          var categories = vm.activities.map(function(item) {
            return item.CategoryName;
          });
          var filter_act = vm.activities.map(function(item) {
            return item.name;
          });
          
          vm.maxActiveResFlag = response.maxActiveReservation;
          if (vm.maxActiveResFlag>0){
            vm.dayStartRes = response.maxActiveReservationStartDay;
          }else{
            vm.dayStartRes = 0;
          }
          vm.categories = categories.filter(unique);
          vm.categories.sort();
          vm.filter_act = filter_act.filter(unique);
          vm.filter_act.sort();
          vm.no_activities = (vm.activities.length === 0)?true:false;
          
          vm.show_selectCat = (vm.categories.length>1)?true:false;
          vm.show_selectAct = (vm.filter_act.length>1)?true:false;
          
          

        }
      });
    };

    vm.reservation = function (id,at,day,desc) {
      var data = {"usr":id,"at":at,"day":day,"description":desc,"cId":vm.infocuponera.id};
// console.log(day);
      Request.post('cuponerareservation/',true,data,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.success"));
          vm.load();
          vm.sendNotification(response.description.id,'confirm');

          //if payment post to payment page
          // window.open('payment');
          ////
        }
      });
    };

    vm.cancelReservation = function (id) {
      Request.delete('cuponerareservation/?id='+id+'&cId='+vm.infocuponera.id,true,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
          // toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.load();
          vm.sendNotification(id,'cancel');
        }
      });
    };

    vm.load();

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirmareserva.html',
        controller: 'ModalReservaController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          dependents: function () {
            return vm.dependents;
          },
          action: function () {
            return vm.action;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            switch (vm.action) {
              case 'PARENT':
                vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
              break;
              case 'DEPENDENT':
                vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'CANCEL':
                vm.cancelReservation(vm.activity.reservationId);
              break;
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.openVirtual = function (size, parentSelector) {      
      var parentElem = parentSelector ? angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'clasevirtual.html',
        controller: 'ModalVirtualController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
        
          // if(result.status === 'OK'){
          //   vm.activity = result.activity;
          //   switch (vm.action) {
          //     case 'PARENT':
          //       vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
          //     break;
          //     case 'DEPENDENT':
          //       vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
          //     break;
          //     case 'CANCEL':
          //       vm.cancelReservation(vm.activity.reservationId);
          //     break;
          //   }
          // }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.doReservation = function(action,activity){
      vm.activity = activity;
      vm.activity.guests = "";
      vm.action = action;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      vm.moredays = vm.daycount;
      if(action === 'PARENT' || action === 'DEPENDENT'){
        vm.title = $translate.instant("reserve.modal_title_confirm");
      }else if(action === 'CANCEL'){
        vm.title = $translate.instant("reserve.modal_title_cancel");
      }
      
      vm.open();
    };

    vm.showVirtual = function(activity){
      vm.title = $translate.instant("reserve.view_class");
      vm.activity = activity;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      
      vm.openVirtual();
    };

    vm.showOccupancy = function(max,total){
      if(max === -1){
        return true;
      }
      var occupancy = max-total;
      if(occupancy<1){
        return false;
      }
      return true;
    };

    vm.showInfo = function(activity){
      vm.activity = activity;
      vm.openInfo();
    };

    vm.openInfo = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'showInfo.html',
        controller: 'ModalInfoController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.addToWaiting = function(activity){
      vm.activity = activity;
      vm.title = $translate.instant("reserve.add_to_waitinglist");
      vm.openWaitingList();
    };

    vm.openWaitingList = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'waitingreservation.html',
        controller: 'ModalWaitingController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          user: function () {
            return $rootScope.user;
          },
          members: function () {
            return vm.dependents;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.member = result.member;
            if(vm.member && vm.activity){

              var data = {"usr":vm.member,"at":vm.activity.id,"day":vm.daycount};

              Request.post('waitinglist/',true,data,function(response) {
                if(response.status !== 0){
                  toaster.pop('error', "", $translate.instant('api.'+response.description));
                }else{
                  toaster.pop('success', "", $translate.instant("reserve.waitinglist_added"));
                }
              });
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };



    vm.viewDetail = function(){
      vm.detail = vm.cuponera_detail;
      vm.openDetailCupon();
    };

    vm.openDetailCupon = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      size = "lg";
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'detailCupon.html',
        controller: 'ModalDetalleCuponeraController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          detail: function () {
            return vm.detail;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

  });

  angular.module('ufcw').controller('ModalReservaController', function ($uibModalInstance,$log,title,day,dependents,action,activity,toaster,$translate,Request) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.dependents = dependents;
    vm.action = action;
    vm.guest = [];
    vm.error_guest = [];
    vm.ok = function () {
      try {
        if(vm.action === 'DEPENDENT' && !vm.selDependent){
          throw $translate.instant("reserve.must_select_dependent");
        }
        
        if(vm.guest.length > 0){
          vm.activity.guests = vm.guest.toString();
        }

        if(!vm.activity.reservationId && vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guest.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
            }
          });
        }else{
          $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
        }

      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalWaitingController', function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalInfoController', function ($uibModalInstance,$log,activity,toaster) {
    var vm = this;
    
    vm.activity = activity;
    
    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalDetalleCuponeraController', function ($uibModalInstance,$log,detail,toaster,$translate) {
    var vm = this;
    
    vm.detail = detail.reservations;
    vm.title = detail.title;

    var STATUS_MAP = {
      CUPONERARESERVED: $translate.instant('reserve.status_reserved'),
      CUPONERACANCELED: $translate.instant('reserve.status_canceled'),
      null: '',
      '': '',
      undefined: '',
    };

    vm.haveRes = (vm.detail.length>0)?true:false;
    
    vm.detail.map(function(item){
      item.statusText = STATUS_MAP[item.status];
      item.reservationdate = new Date(item.reservationdate);
    });

    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalVirtualController', function ($uibModalInstance,$log,title,activity) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
  
    

    vm.ok = function () {
      // try {
      //   if(vm.action === 'DEPENDENT' && !vm.selDependent){
      //     throw "Debe seleccionar un familiar";
      //   }
      //   if(vm.inv1){
      //     vm.activity.guests += ""+vm.inv1;
      //   }
      //   if(vm.inv2){
      //     vm.activity.guests += ", "+vm.inv2;
      //   }
      //   if(vm.inv3){
      //     vm.activity.guests += ", "+vm.inv3;
      //   }
      //   $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
      // } catch (error) {
      //   toaster.pop('error', "", error);
      // }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'clasevirtual.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });


  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirmareserva.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'detailCupon.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.detail = vm.resolve.detail;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').controller('ModalWaitingController', function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });