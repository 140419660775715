'use strict';

angular.module('ufcw')
  .controller('HomeController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;

    vm.reservation_enabled = true;
    vm.carnetExpirated = false;
    vm.carnetAlreadyExpired = false;
    vm.payExpirated = false;
    vm.payment_text = "";
    vm.favorites = false;
    
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');
    vm.show_msg = $localstorage.get('showMsg');
    var config  = $localstorage.getObject('config');
    vm.useRoutines = $localstorage.get('useRoutines');
    
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.activities");

    if(EnvConfig.show_carnet_expiration || config.validateCarnetExpiration === 1){
      if($rootScope.user.carnetExpiration && $rootScope.user.carnetExpiration !== '0000-00-00'){
        var currentDate = new Date();
        var carnetExpirationDate = new Date($rootScope.user.carnetExpiration);
        var dateDiff = carnetExpirationDate.getTime() - currentDate.getTime();
        var days = Math.round(dateDiff / (1000 * 60 * 60 * 24));
        vm.carnetExpirated = days < 30 ? true:false;
        vm.carnetAlreadyExpired = days < 0 ? true:false;
      }
    }
    
    if(EnvConfig.show_payment_expiration){
      if($rootScope.user.payExpiration && $rootScope.user.payExpiration !== '0000-00-00'){
        var currentDatePay = new Date();
        var payExpirationDate = new Date($rootScope.user.payExpiration);
        var dateDiffPay = payExpirationDate.getTime() - currentDatePay.getTime();
        var daysPay = Math.round(dateDiffPay / (1000 * 60 * 60 * 24));
        if($rootScope.user.payMode === 'ANUAL'){
          vm.payment_text = $translate.instant('general.payExpiration_anual_message');
          vm.payExpirated = daysPay < 15 ? true:false;
        }
        if($rootScope.user.payMode === 'MENSUAL'){
          vm.payment_text = $translate.instant('general.payExpiration_mensual_message');
          vm.payExpirated = daysPay < 0 ? true:false;
        }
      }
    }
    
        //para sinergia
        // if($rootScope.projectname==="sinergia"){    
        //   if(angular.isDefined($window.Beacon)){
        //     $window.Beacon('identify', {
        //       name: user.firstName+" "+user.lastName,
        //       email: user.email,
        //       });
        //   }
        // }
        
        //fin sinergia

    //get activities for user category
    Request.get('activitycategory/?from=FRONTEND',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
          item.description = (item.description)?angular.fromJson(item.description):item.description;
        });

        vm.activities.forEach(function (activity) {
          if(activity.favorite===1){
            vm.favorites = true;
          }            
        }
        );
        
        // vm.favorites = vm.activities.reduce((accumulator, current) => {
        //   if (current.favorite === 1) {
        //     accumulator.push(current);
        //   }
        //   return accumulator;
        // }, []);
        // $log.log(vm.favorites);
      }
    });

    //CUPONERAS
    Request.get('usercuponera',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.cuponeras = response.description;
        if($rootScope.user.userAccess === "CUPONERA" && vm.cuponeras.length === 0 && config.usePayment === 1){
          $state.go('comprarcuponera');
        }
        vm.cuponeras.map(function(item){
          item.usercuponeraCreated = (item.usercuponeraCreated)? new Date(item.usercuponeraCreated):item.usercuponeraCreated;
        });
      }
    });

    // //FIXED ACTIVITIES
    // Request.get('fixedactivities',true,function(response) {
    //   if(response.status !== 0){
    //     toaster.pop('info', "", $translate.instant('api.'+response.description));
    //   }else{
    //     vm.fixedActivities = response.description;
    //     //if($rootScope.user.userAccess === "CUPONERA" && vm.cuponeras.length === 0){
    //     //  $state.go('comprarcuponera');
    //     //}
    //     //vm.cuponeras.map(function(item){
    //     //  item.usercuponeraCreated = (item.usercuponeraCreated)? new Date(item.usercuponeraCreated):item.usercuponeraCreated;
    //    // });
    //   }
    // });

  // MODAL
  vm.animationsEnabled = true;

  vm.open = function (size, parentSelector) {
    var tam = (size)?size:'lg';
    var parentElem = parentSelector ?
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    var modalInstance = $uibModal.open({
      animation: vm.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'initialmsg.html',
      controller: 'ModalInitialMsgController',
      controllerAs: 'modalCtrl',
      backdrop: 'static',
      keyboard: false,
      size: tam,
      appendTo: parentElem,
      resolve: {
        msg: function () {
          return vm.msg;
        }
      }
    });

    modalInstance.result.then(function () {
      }, function () {
    });
  };

  vm.showInit = function(){
    var config = $localstorage.getObject('config');
    vm.msg = config.initialMsg;
    vm.open();
  };

  if(vm.show_msg === 'true'){
    vm.showInit();
  }

  vm.viewDetail = function(cuponera_detail){
    if(cuponera_detail.activityCatType === 'INTERNAL'){
      Request.get('usercuponera/?id='+cuponera_detail.usercuponeraId+"&detail=true",true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.cuponera_detail = {}; 
          vm.cuponera_detail.reservations = response.description.reservations;
          vm.cuponera_detail.title = response.description.cuponera[0];
          vm.detail = vm.cuponera_detail;
          vm.openDetailCupon();
        }
      });
    }
  };

  vm.openDetailCupon = function (size, parentSelector) {
    var parentElem = parentSelector ? 
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    size = "lg";
    var modalInstance = $uibModal.open({
      animation: vm.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'detailCupon.html',
      controller: 'ModalDetalleCuponeraHomeController',
      controllerAs: 'modalCtrl',
      size: size,
      backdrop: 'static',
      keyboard: false,
      appendTo: parentElem,
      resolve: {
        detail: function () {
          return vm.detail;
        }
      }
    });

    modalInstance.result.then(function () {
    }, function () {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  };


  });

  angular.module('ufcw').controller('ModalInitialMsgController', function ($uibModalInstance,$log,msg,$localstorage,$sce) {
    var vm = this;
    vm.msg = $sce.trustAsHtml(msg);

    vm.ok = function () {
      $uibModalInstance.close({status: 'OK'});
    };

    vm.cancel = function () {
      $localstorage.set('showMsg',false);
      $uibModalInstance.dismiss('cancel');
    };

  });

  angular.module('ufcw').component('modalComponent', {
  templateUrl: 'initialmsg.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: function () {
    var vm = this;

    vm.$onInit = function () {
      vm.msg = vm.resolve.msg;
    };

    vm.ok = function () {
      vm.close({$value: 'OK'});
    };

    vm.cancel = function () {
      vm.dismiss({$value: 'cancel'});
    };
  }
});

angular.module('ufcw').controller('ModalDetalleCuponeraHomeController', function ($uibModalInstance,$log,detail,toaster,$translate) {
  var vm = this;
  
  vm.detail = detail.reservations;
  vm.title = detail.title;

  var STATUS_MAP = {
    CUPONERARESERVED: $translate.instant('reserve.status_reserved'),
    CUPONERACANCELED: $translate.instant('reserve.status_canceled'),
    null: '',
    '': '',
    undefined: '',
  };

  vm.haveRes = (vm.detail.length>0)?true:false;
  
  vm.detail.map(function(item){
    item.statusText = STATUS_MAP[item.status];
    item.reservationdate = new Date(item.reservationdate);
  });

  vm.ok = function () {
    try {
      $uibModalInstance.close({status: 'OK'});
    } catch (error) {
      toaster.pop('error', "", error);
    }
  };

  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});

angular.module('ufcw').component('modalComponent', {
  templateUrl: 'detailCupon.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: function () {
    var vm = this;

    vm.$onInit = function () {
      vm.detail = vm.resolve.detail;
    };

    vm.ok = function () {
      vm.close({$value: 'OK'});
    };
  }
});