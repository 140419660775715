'use strict';
angular.module('ufcw')
  .controller('MyProfileController', function ($log, Request, $localstorage, $rootScope, $uibModal, $document, toaster, $translate, $state, EnvConfig) {
    var vm = this;
    vm.errorMsg = null;
    vm.user = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    var config  = $localstorage.getObject('config');
    vm.has_dependent = (vm.dependents.length === 0) ? false : true;
    vm.user_files = $localstorage.getObject('user_files');
    vm.user.profile_img = "images/Icono_persona.png";
    vm.user.cedula = "";
    vm.user.csalud = "";
    vm.user.certif = "";
    
    vm.hideMemberId = (config.hideMemberId===1)?true:false;
    vm.allowUploadPic = (config.dontAllowProfileImgChange===1)?false:true;
    
    var documents = ['txt', 'doc', 'docx'];
    var spreadsheet = ['csv', 'xls', 'xlsx'];
    var html = ['html'];
    var pdf = ['pdf'];

    vm.getFileIcon = function (file) {
      if(file){
        var dot = file.lastIndexOf('.');
        var extension = file.substring(dot+1,file.length);
        if (documents.includes(extension)) {
          return EnvConfig.URL_IMAGES + 'file-doc.png';
        }
        if (spreadsheet.includes(extension)) {
          return EnvConfig.URL_IMAGES + 'file-excel.png';
        }
        if (html.includes(extension)) {
          return EnvConfig.URL_IMAGES + 'file-html.png';
        }
        if (pdf.includes(extension)) {
          return EnvConfig.URL_IMAGES + 'file-pdf.png';
        }
        return file;
      }
      return null;
    };

    if(vm.user.carnetExpiration === "0000-00-00"){
      vm.user.carnetExpiration = '';
    }
    if(vm.user.payExpiration === "0000-00-00"){
      vm.user.payExpiration = '';
    }

    if (vm.user_files) {
      vm.user_profile = vm.user_files.filter(function (item) {
        if (item.fileType === "PERFIL") {
          return item;
        }
      });
      vm.user_cedula = vm.user_files.filter(function (item) {
        if (item.fileType === "CEDULA") {
          return item;
        }
      });
      vm.user_csalud = vm.user_files.filter(function (item) {
        if (item.fileType === "C_SALUD") {
          return item;
        }
      });
      vm.user_certif = vm.user_files.filter(function (item) {
        if (item.fileType === "NO_COVID") {
          return item;
        }
      });


      if (vm.user_profile.length > 0) {
        vm.user.profile_img = EnvConfig.URL_FILES + vm.user_profile[0].companyId + '/' + vm.user_profile[0].userId + '/' + vm.user_profile[0].fileName;
      }
      if (vm.user_cedula.length > 0) {
        vm.user.cedula = {};
        vm.user.cedula.path = EnvConfig.URL_FILES + vm.user_cedula[0].companyId + '/' + vm.user_cedula[0].userId + '/' + vm.user_cedula[0].fileName;
        vm.user.cedula.icon = vm.getFileIcon(vm.user.cedula.path);
      }
      if (vm.user_csalud.length > 0) {
        vm.user.csalud = {};
        vm.user.csalud.path = EnvConfig.URL_FILES + vm.user_csalud[0].companyId + '/' + vm.user_csalud[0].userId + '/' + vm.user_csalud[0].fileName;
        vm.user.csalud.icon = vm.getFileIcon(vm.user.csalud.path);
      }
      if (vm.user_certif.length > 0) {
        vm.user.certif = {};
        vm.user.certif.path = EnvConfig.URL_FILES + vm.user_certif[0].companyId + '/' + vm.user_certif[0].userId + '/' + vm.user_certif[0].fileName;
        vm.user.certif.icon = vm.getFileIcon(vm.user.certif.path);
      }
    }
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.my_profile");
    vm.update = function (user) {
      Request.put('user/?id=' + user.id, true, user, function (response) {
        if (response.status !== 0) {
          vm.user = $localstorage.getObject('user');
          toaster.pop('error', "", $translate.instant('api.' + response.description));
        } else {
          vm.changeLang(user.lang);
          $localstorage.setObject('user', response.description);
          toaster.pop('success', "", $translate.instant("profile.saved"));
        }
      });
    };
    var LANG_MAP = {
      es: $translate.instant('lang.es'),
      en: $translate.instant('lang.en'),
      null: '',
      '': '',
      undefined: '',
    };
    vm.user.lang_text = LANG_MAP[vm.user.lang];
    // MODAL
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ?
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'profile.html',
        controller: 'ModalProfileController',
        controllerAs: 'modalCtrl',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          user: function () {
            return vm.user;
          }
        }
      });
      modalInstance.result.then(function (result) {
        if (result.status === 'OK') {
          if (result.img) {
            vm.uploadProfileImg(result.img, result.user);
          }
          vm.update(result.user);
        }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };
    vm.edit = function () {
      vm.open();
    };
    vm.changeLang = function (lang) {
      $translate.use(lang);
      $state.reload();
    };
    vm.uploadProfileImg = function (file, user) {
      var data = [{ name: "userId", value: user.id }, { name: "fileType", value: "PERFIL" }];
      Request.file('userfile', true, data, file, function (response) {
        if (response.status !== 0) {
          toaster.pop('error', "", $translate.instant('api.' + response.description));
        } else {
          $localstorage.setObject('user_files', response.description);
          toaster.pop('success', "", $translate.instant("profile.saved"));
        }
      });
    };

  });

angular.module('ufcw').controller('ModalProfileController', function ($uibModalInstance, $log, user, toaster, $translate, $window, Request, $localstorage) {
  var vm = this;
  vm.user = user;

  var config  = $localstorage.getObject('config');
  vm.allowUploadPic = (config.dontAllowProfileImgChange===1)?false:true;
  vm.ok = function () {
    try {
      if (vm.user.passwordOld && !vm.user.passwordNew) {
        throw $translate.instant("profile.new_pwd_invalid");
      }
      if (vm.user.passwordNew && !vm.user.passwordOld) {
        throw $translate.instant("profile.current_pwd_invalid");
      }
      if (!vm.user.email) {
        throw $translate.instant("signin.email_invalid");
      }
      if (!vm.user.phone) {
        throw $translate.instant("profile.phone_invalid");
      }else{
        vm.user.phone = vm.user.phone.replaceAll(/\s/g,'');
        if(isNaN(vm.user.phone)){
          throw $translate.instant("profile.phone_invalid");
        }
      }
      // llamo a guardar los archivos
      if(vm.fileCedula){
        vm.uploadFile(vm.fileCedula, vm.user.id, "CEDULA");
      }
      if(vm.fileCsalud){
        vm.uploadFile(vm.fileCsalud, vm.user.id, "C_SALUD");
      }
      if(vm.fileCertificado){
        vm.uploadFile(vm.fileCertificado, vm.user.id, "NO_COVID");
      }
      $uibModalInstance.close({ user: vm.user, img: vm.file, status: 'OK' });
    } catch (error) {
      toaster.pop('error', "", error);
    }
  };
  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
  vm.langs = [{ "id": "es", "value": $translate.instant('lang.es') }, { "id": "en", "value": $translate.instant('lang.en') }];

  vm.openFile = function (value) {
    $window.document.getElementById(value).click();
  };

  vm.uploadFile = function (file, userid, filetype) {
    var data = [{ name: "userId", value: userid }, { name: "fileType", value: filetype }];

    Request.file('userfile', true, data, file, function (response) {
      if (response.status !== 0) {
        toaster.pop('error', "", $translate.instant('api.' + response.description));
      } else {
        
        $localstorage.setObject('user_files', response.description);
        toaster.pop('success', "", $translate.instant("profile.saved"));
      }
    });
  };

});

angular.module('ufcw').component('modalComponent', {
  templateUrl: 'profile.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: function () {
    var vm = this;
    vm.$onInit = function () {
      vm.user = vm.resolve.user;
    };
    vm.ok = function () {
      vm.close({ $value: 'OK' });
    };
    vm.cancel = function () {
      vm.dismiss({ $value: 'cancel' });
    };
  }
});

