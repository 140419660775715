"use strict";

angular
  .module("ufcw")
  .controller(
    "ReservaactividadcustomController",
    function (
      $rootScope,
      $log,
      $location,
      Request,
      $localstorage,
      $uibModal,
      $document,
      $state,
      toaster,
      $translate
    ) {
      var vm = this;
      $rootScope.headLander = true;
      $rootScope.titulopagina = $translate.instant("reserve.title");

      vm.reservation_enabled = true;
      vm.carnetExpirated = false;
      vm.payExpirated = false;
      vm.payment_text = "";
      vm.inittimeshow = "";
      vm.guestQty = 0;
      vm.guest = [];
      vm.ci_valid = [];
      vm.guest_control = "OK";

      vm.activity = $localstorage.getObject("activitycustom");
      var id = vm.activity.id || $state.params.activity.id;

      if (!id) {
        $state.go("home");
      }

      vm.moredays = vm.activity.moredays;
      vm.actualday = vm.activity.actualday;
      vm.day = vm.activity.day;

      vm.type_activity = "";
      vm.time_activity = "";

      var user = $localstorage.getObject("user");
      vm.dependents = $localstorage.getObject("dependents");
      vm.has_dependent = vm.dependents.length === 0 ? false : true;
      var config = $localstorage.getObject("config");
      vm.useBuilding = config.useBuilding;
      if (config.reservationEnabled === 0) {
        vm.reservation_enabled = false;
        vm.notice_title = config.noticeAlertTitle;
        vm.notice_text = config.noticeAlertText;
      } else {
        vm.reservation_enabled = true;
      }

      $rootScope.user = user;
      vm.showNextDay = true;

      vm.sendNotification = function (id, action) {
        var data = { type: "email", action: action, reservationId: id };
        Request.post("notification/", true, data, function () {});
      };

      vm.load_activitytime_custom = function () {
        Request.get("activitytimecustom/", true, function (response) {
          if (response.status !== 0) {
            vm.errorMsg = $translate.instant(response.description);
          } else {
            vm.type_activity_list = response.description;
          }
        });
      };

      vm.changeTimeCustom = function () {
        vm.guestQty = 0;
        vm.guest = [];
        vm.ci_valid = [];

        var item = vm.type_activity_list.filter(function (item) {
          if (item.id === vm.type_activity) {
            return item;
          }
        });

        var retorno = [];
        var index = item[0].mintime;
        while (index <= item[0].maxtime) {
          retorno.push(index);
          index += 30;
        }

        vm.ci_valid = [];
        for (var i = 0; i < item[0].guestsqts; i++) {
          vm.ci_valid[i] = true;
        }

        vm.options_time_custom = retorno;
        vm.time_activity = retorno[0];
      };

      vm.daycount = 0;

      vm.changeInitTime = function (reservation) {
        var i = 0;
        var error = false;
        var error_msg = "Su reserva se superpone con una existente";
        for (var index = 0; index < vm.options_time.length; index++) {
          if (vm.options_time[index].time === reservation.time) {
            i = index;
            if (vm.time_activity === 60) {
              //validate if have a resevation
              if (
                vm.options_time[index].reserved !== -1 ||
                vm.options_time[index + 1].reserved !== -1
              ) {
                error = true;
              }
              //validate if have a suspended
              if (
                vm.options_time[index].suspended !== -1 ||
                vm.options_time[index + 1].suspended !== -1
              ) {
                error_msg = "Su reserva se superpone con una hora suspendida";
                error = true;
              }
              //validate if have a block
              if (
                vm.options_time[index].block !== -1 ||
                vm.options_time[index + 1].block !== -1
              ) {
                error_msg = "Su reserva se superpone con una hora cancelada";
                error = true;
              }
            }
            if (vm.time_activity === 90) {
              if (
                vm.options_time[index].reserved !== -1 ||
                vm.options_time[index + 1].reserved !== -1 ||
                vm.options_time[index + 2].reserved !== -1
              ) {
                error = true;
              }

              if (
                vm.options_time[index].suspended !== -1 ||
                vm.options_time[index + 1].suspended !== -1 ||
                vm.options_time[index + 2].suspended !== -1
              ) {
                error_msg = "Su reserva se superpone con una hora suspendida";
                error = true;
              }

              if (
                vm.options_time[index].block !== -1 ||
                vm.options_time[index + 1].block !== -1 ||
                vm.options_time[index + 2].block !== -1
              ) {
                error_msg = "Su reserva se superpone con una hora bloqueada";
                error = true;
              }
            }
          } else {
            vm.options_time[index].selected = false;
          }
        }
        if (error) {
          toaster.pop("error", "", error_msg);
          return;
        }

        if (vm.time_activity === 60) {
          vm.options_time[i].selected = true;
          vm.options_time[i + 1].selected = true;
        }
        if (vm.time_activity === 90) {
          vm.options_time[i].selected = true;
          vm.options_time[i + 1].selected = true;
          vm.options_time[i + 2].selected = true;
        }

        if (reservation.reserved === -1) {
          var date_init = new Date(reservation.time);
          var date_format = date_init.getFullYear() + "-" + (date_init.getMonth() + 1) + "-" + date_init.getDate() + " " + date_init.getHours() + ":" + date_init.getMinutes() + ":00";
          vm.inittimeshow = date_init.getHours() + ":" + date_init.getMinutes();
          vm.inittime = date_format;
        } else {
          vm.inittime = null;
        }
      };

      vm.load = function () {
        Request.get(
          "reservationcustom/?activityId=" + id + "&date=" + vm.actualday,
          true,
          function (response) {
            if (response.status !== 0) {
              //  $state.go("signin");
            } else {
              // vm.activity = response.description.activity;
              vm.reservations = response.description.reservations;
              vm.suspended = response.description.activitycanceled;
              vm.activity_sel = response.description.activity;

              //create array reservations times
              var reservations_time = [];
              var my_reservations = [];
              var my_reservations_id = [];
              var suspended_time = [];
              var block_time = [];

              //process all reservation
              vm.reservations.forEach(function (val) {
                var starttime_reservation = new Date(val.reservationdate);

                //add starttime
                reservations_time.push(starttime_reservation.getTime());

                if (val.userId === $rootScope.user.id) {
                  my_reservations.push(starttime_reservation.getTime());
                  my_reservations_id.push({
                    time: starttime_reservation.getTime(),
                    id: val.id,
                  });
                }

                //add first hour
                if (val.reservationtime === 60 || val.reservationtime === 90) {
                  reservations_time.push(
                    starttime_reservation.setMinutes(
                      starttime_reservation.getMinutes() + 30
                    )
                  );
                  if (val.userId === $rootScope.user.id) {
                    my_reservations.push(starttime_reservation.getTime());
                    my_reservations_id.push({
                      time: starttime_reservation.getTime(),
                      id: val.id,
                    });
                  }
                }

                //add second hour
                if (val.reservationtime === 90) {
                  reservations_time.push(
                    starttime_reservation.setMinutes(
                      starttime_reservation.getMinutes() + 30
                    )
                  );
                  if (val.userId === $rootScope.user.id) {
                    my_reservations.push(starttime_reservation.getTime());
                    my_reservations_id.push({
                      time: starttime_reservation.getTime(),
                      id: val.id,
                    });
                  }
                }
              });

              var today = new Date(vm.actualday);
              var starttime_suspended = null;
              var endtime_suspended = null;

              
              vm.suspended.forEach(function (val) {
                if (val.hours) {
                  if (val.hours === "[]") {
                    // all hours
                    starttime_suspended = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) + "-" + today.getDate() + " " + vm.activity_sel.starttime);
                    endtime_suspended = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) + "-" + today.getDate() + " " + vm.activity_sel.endtime);

                    while ( starttime_suspended.getTime() < endtime_suspended.getTime()) {
                      if (val.status === "BLOCK") {
                        block_time.push(starttime_suspended.getTime());
                      } else {
                        suspended_time.push(starttime_suspended.getTime());
                      }

                      starttime_suspended.setMinutes(starttime_suspended.getMinutes() + 30);
                    }
                  } else {
                    var hours = angular.fromJson(val.hours);

                    hours.forEach(function (periods) {
                      starttime_suspended = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) +"-" +today.getDate() +" " +periods[0] +":00");
                      endtime_suspended = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) + "-" + today.getDate() + " " + periods[1] + ":00");

                      while (starttime_suspended.getTime() < endtime_suspended.getTime()) {
                        if (val.status === "BLOCK") {
                          block_time.push(starttime_suspended.getTime());
                        } else {
                          suspended_time.push(starttime_suspended.getTime());
                        }
                        starttime_suspended.setMinutes(starttime_suspended.getMinutes() + 30);
                      }
                    });
                  }
                }
              });

              var stime = vm.moredays === 0 ? today.getMinutes() > 29 ? today.getHours() + 1 + ":00" : today.getHours() + ":30" : vm.activity.starttime;
              var starttime = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) +"-" +today.getDate() +" " +stime);
              var endtime = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) +"-" +today.getDate() +" " +vm.activity.endtime);
              var time_diff = endtime.getTime() - starttime.getTime();
              var time_diff_minutes = parseInt(Math.abs(time_diff)) / (1000 * 60);
              var options_limit = parseInt(time_diff_minutes / 30) - 1;
              var options = [];
              var temp_startime2 = starttime.getTime();
              var reservationId2 = my_reservations_id.filter(function (item) {
                if (item.time === temp_startime2) {
                  return item;
                }
              });

              options.push({
                id: reservationId2.length !== 0 ? reservationId2[0].id : 0,
                time: temp_startime2,
                reserved: reservations_time.indexOf(temp_startime2),
                suspended: suspended_time.indexOf(temp_startime2),
                block: block_time.indexOf(temp_startime2),
                my: my_reservations.indexOf(temp_startime2),
                selected: false,
              });

              for (var index = 0; index < options_limit; index++) {
                var temp_startime = starttime.setMinutes(starttime.getMinutes() + 30);
                var reservationId = my_reservations_id.filter(function (item) {
                  if (item.time === temp_startime) {
                    return item;
                  }
                });

                options.push({
                  id: reservationId.length !== 0 ? reservationId[0].id : 0,
                  time: temp_startime,
                  reserved: reservations_time.indexOf(temp_startime),
                  suspended: suspended_time.indexOf(temp_startime),
                  block: block_time.indexOf(temp_startime),
                  my: my_reservations.indexOf(temp_startime),
                  selected: false,
                });
              }
              vm.options_time = options;
            }
          }
        );
      };

      vm.reservation = function (id, at, day, desc, inittime, type_activity) {
        var data = {
          usr: id,
          at: at,
          day: day,
          description: desc,
          inittime: inittime,
          type: type_activity,
        };

        Request.post("reservationcustom/", true, data, function (response) {
          if (response.status !== 0) {
            var res = response.description.substring(0, 4);
            var prefix = res === "api." ? res : "";
            toaster.pop(
              "error",
              "",
              $translate.instant(prefix + response.description)
            );
          } else {
            toaster.pop("success", "", $translate.instant("reserve.success"));
            vm.load();
            vm.sendNotification(response.description.id, "confirm");
            $state.go("home");
          }
        });
      };

      vm.cancelReservation = function (id) {
        Request.delete(
          "reservationcustom/?id=" + id,
          true,
          function (response) {
            if (response.status !== 0) {
              var res = response.description.substring(0, 4);
              var prefix = res === "api." ? res : "";
              toaster.pop(
                "error",
                "",
                $translate.instant(prefix + response.description)
              );
            } else {
              toaster.pop(
                "success",
                "",
                $translate.instant("reserve.canceled_ok")
              );
              vm.load();
              $state.go("home");
            }
          }
        );
      };

      vm.load_activitytime_custom();

      // MODAL THINGS
      vm.animationsEnabled = true;

      vm.open = function (size, parentSelector) {
        var modalInstance;
        var parentElem = parentSelector ? angular.element( $document[0].querySelector(".modal-demo " + parentSelector)): undefined;
        modalInstance = $uibModal.open({
          animation: vm.animationsEnabled,
          ariaLabelledBy: "modal-title",
          ariaDescribedBy: "modal-body",
          templateUrl: "confirmareservacustom.html",
          controller: "ModalReservaCustomController",
          controllerAs: "modalCtrl",
          size: size,
          backdrop: "static",
          keyboard: false,
          appendTo: parentElem,
          resolve: {
            title: function () {
              return vm.title;
            },
            day: function () {
              return vm.day;
            },
            dependents: function () {
              return vm.dependents;
            },
            action: function () {
              return vm.action;
            },
            activity: function () {
              return vm.activity;
            },
            inittime: function () {
              return vm.inittime;
            },
            time_activity: function () {
              return vm.time_activity;
            },
            type_activity: function () {
              return vm.type_activity;
            },
            guests: function () {
              return vm.guest;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            if (result.status === "OK") {
              vm.activity = result.activity;
              switch (vm.action) {
                case "PARENT":
                  vm.reservation(
                    vm.id,
                    vm.at,
                    vm.moredays,
                    vm.guest.toString(),
                    vm.inittime,
                    vm.time_activity
                  );
                  break;
                case "DEPENDENT":
                  vm.reservation(
                    result.dependent,
                    vm.at,
                    vm.moredays,
                    vm.guest.toString()
                  );
                  break;
                case "CANCEL":
                  vm.cancelReservation(vm.reservationId);
                  break;
              }
            } else {
              vm.options_time.map(function (item) {
                item.selected = false;
              });
            }
          },
          function () {}
        );
      };

      vm.gridenable = function (type_activity, time_activity) {
        if (type_activity !== "" && time_activity !== "") {
          vm.guestQty = vm.getActivityCustom(type_activity).guestsqts;
          vm.load();
        }
      };

      vm.getActivityCustom = function (activity) {
        var ret = vm.type_activity_list.filter(function (item) {
          if (item.id === activity) {
            return item;
          }
        });
        return ret[0];
      };

      vm.checkGuests = function (guests) {
        Request.get(
          "user/?guests=" + guests.toString(),
          true,
          function (response) {
            if (response.code === "ERROR") {
              $log.log(response);
              return false;
            } else {
              $log.log("True Guest");
              return true;
            }
          }
        );
      };

      vm.doReservation = function (
        action,
        activity,
        inittime,
        type_activity,
        time_activity,
        reservationId,
        guests
      ) {
        if (action === "CANCEL") {
          var itime;
          vm.options_time.map(function (item) {
            if (item.id === reservationId && !itime) {
              itime = item.time;
            }
          });
          vm.inittime = itime;
          vm.reservationId = reservationId;
          vm.action = action;
          vm.title = $translate.instant("reserve.modal_title_cancel");
          vm.open();
        } else {
          vm.validateGuests(guests, type_activity, function (res) {
            if (res !== "OK") {
              toaster.pop("error", "", res);
              return;
            }

            if (
              angular.isDefined(inittime) &&
              inittime !== null &&
              type_activity !== "" &&
              time_activity !== ""
            ) {
              vm.activity = activity;
              vm.guestQty = vm.getActivityCustom(type_activity).guestsqts;
              vm.reservationId = reservationId;
              vm.action = action;
              vm.activity.guestQty = vm.guestQty;
              vm.activity.typeReservation = activity.typeReservation;
              vm.at = vm.activity.id;
              vm.id = $rootScope.user.id;
              vm.moredays = vm.daycount;

              vm.title = $translate.instant("reserve.modal_title_confirm");
            } else {
              toaster.pop(
                "error",
                "",
                $translate.instant(
                  "Debe seleccionar tipo, tiempo y hora de la actividad"
                )
              );
              return;
            }
            vm.open();
          });
        }
      };

      vm.validateGuests = function (guests, type_activity, cb) {
        var responseCB = "";
        Request.get(
          "user/?guests=" + guests.toString(),
          true,
          function (response) {
            if (response.code === "ERROR") {
              responseCB = "El documento ingresado no corresponde a un socio";
            } else {
              responseCB = "OK";

              var guestQty = vm.getActivityCustom(type_activity).guestsqts;

              if (guestQty !== guests.length) {
                responseCB = "Debe ingresar el/los documentos";
              }

              var exists_null = guests.filter(function (item) {
                return item === null;
              });

              if (exists_null.length) {
                responseCB = "Debe ingresar el/los documentos";
              }

              var exists_duplicate = guests.filter(function (item, index) {
                return guests.indexOf(item) !== index;
              });

              if (exists_duplicate.length) {
                responseCB = "Hay una o más documento que se repiten";
              }

              //check if same documents of current user
              var exists_same_doc = guests.filter(function (item) {
                return $rootScope.user.identificationNumber === item;
              });

              if (exists_same_doc.length) {
                responseCB = "El documento ingresado es el del socio";
              }
            }
            return cb(responseCB);
          }
        );
      };
    }
  );

angular
  .module("ufcw")
  .controller(
    "ModalReservaCustomController",
    function (
      $uibModalInstance,
      $log,
      title,
      day,
      dependents,
      action,
      activity,
      time_activity,
      type_activity,
      inittime,
      guests,
      toaster,
      $translate,
      Request,
      $localstorage
    ) {
      var vm = this;
      vm.title = title;
      vm.activity = activity;
      vm.time_activity = time_activity;
      vm.type_activity = type_activity;
      vm.inittime = new Date(inittime);

      vm.day = day;
      vm.dependents = dependents;
      vm.action = action;
      vm.guests = guests;
      vm.isDisabled = false;

      var config = $localstorage.getObject("config");
      vm.useBuilding = config.useBuilding;

      vm.load_activitytime_custom = function () {
        Request.get(
          "activitytimecustom/?id=" + vm.type_activity,
          true,
          function (response) {
            if (response.status !== 0) {
              vm.errorMsg = $translate.instant(response.description);
            } else {
              vm.type_activity_label = response.description.name;
            }
          }
        );
      };
      vm.load_activitytime_custom();

      vm.ok = function () {
        try {
          if (vm.action === "DEPENDENT" && !vm.selDependent) {
            throw $translate.instant("reserve.must_select_dependent");
          }

          $uibModalInstance.close({
            dependent: vm.selDependent,
            activity: vm.activity,
            status: "OK",
          });
        } catch (error) {
          toaster.pop("error", "", error);
          vm.isDisabled = false;
        }
      };

      vm.cancel = function () {
        $uibModalInstance.close({ status: "cancel" });
      };
    }
  );

angular.module("ufcw").component("modalComponent", {
  templateUrl: "confirmareservacustom.html",
  bindings: {
    resolve: "<",
    close: "&",
    dismiss: "&",
  },
  controller: function () {
    var vm = this;

    vm.$onInit = function () {
      vm.title = vm.resolve.title;
      vm.activity = vm.resolve.activity;
      vm.day = vm.resolve.day;
      vm.dependents = vm.resolve.dependents;
      vm.time_activity = vm.resolve.time_activity;
      vm.type_activity = vm.resolve.type_activity;
      vm.inittime = vm.resolve.inittime;
      vm.guests = vm.resolve.guests;
    };

    vm.ok = function () {
      vm.close({ $value: "OK" });
    };

    vm.cancel = function () {
      vm.dismiss({ $value: "cancel" });
    };
  },
});
