'use strict';

angular.module('ufcw')
  .controller('SignupController', function ($rootScope,EnvConfig,Request,$log,$state,toaster,$translate) {
    $rootScope.headLander = false;
    var vm = this;
    vm.errorMsg = null;
    vm.user = {};
    vm.user.companyId = EnvConfig.COMPANY_ID;
    vm.option_cuponera = EnvConfig.show_cuponeras;
    vm.company_name = EnvConfig.footer_app_name;

    if (vm.company_name!=='ENFOQUE'){
      vm.tipoSocio = "socio";
    }else{
      vm.tipoSocio = "cuponera";
    }
   

    function formatDate(num){
      return (num.toString().length===1)?"0"+num:""+num;
    }
    
    vm.signup = function(){

      if(vm.tipoSocio === "cuponera"){
        vm.user.dob = vm.dob.getFullYear()+"-"+formatDate(vm.dob.getMonth()+1)+"-"+formatDate(vm.dob.getDate());  
        Request.post('signupcuponera',false,vm.user,function(response) {
          if(response.status !== 0){
            // vm.errorMsg = response.description;
            toaster.pop('error', "", $translate.instant('api.'+response.description));
          }else{
            toaster.pop('success', "", $translate.instant("signup.success"));
            $state.go('signin');
          }
        });
      }else{
        if(EnvConfig.signup_dob){
          vm.user.dob = vm.dob.getFullYear()+"-"+formatDate(vm.dob.getMonth()+1)+"-"+formatDate(vm.dob.getDate());  
        }
        Request.post('signup',false,vm.user,function(response) {
          if(response.status !== 0){
            // vm.errorMsg = response.description;
            toaster.pop('error', "", $translate.instant('api.'+response.description));
          }else{
            toaster.pop('success', "", $translate.instant("signup.success"));
            $state.go('signin');
          }
        });
      }
      
    };

  });
