'use strict';

angular.module('ufcw')
  .controller('CanchaController', function ($rootScope,$log,$location,Request,$localstorage,$uibModal,$document,$state,toaster,$translate,$window,EnvConfig) {
    
    var vm = this;
    vm.reservation_enabled = true;

    var user  = $localstorage.getObject('user');

    //redirect signin if show cachas config is disabled
    vm.showCanchas = EnvConfig.show_canchas;
    if(!vm.showCanchas){
      $state.go('signin');
    }
    
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    vm.backPath = "/home";
    var token  = $localstorage.get('token');
    if(!token){
      vm.backPath = "/homecanchas";
    }

    vm.showNextDay = true;

    if(user){
      user.profile_img = "images/Icono_persona.png";
      user.nameToShow = user.firstName.split(" ");
      user.nameToShow = user.nameToShow[0];
      $rootScope.user = user;
      $rootScope.headLander = true;
    }    
    
    $rootScope.titulopagina = $translate.instant("Reserva de Canchas");
   
    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    var d = new Date();
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() { });

    };
    
    vm.daycount = 0;

    vm.changeDate = function(option){
      if(option==='ADD'){
        vm.daycount+=1;
        d.setDate(d.getDate() + 1);
      }else{
        vm.daycount-=1;
        d.setDate(d.getDate() - 1);
      }
      if(vm.daycount>0){
        vm.showNextDay = false;
      }else if(vm.daycount===0){
        vm.showNextDay = true;
      }

      var today = new Date();
      var canchaLimit = new Date();
      var maxDaysViewFields  = $localstorage.get('maxDaysViewFields');

      if(maxDaysViewFields > 0){
        
        canchaLimit.setDate(today.getDate() + parseInt(maxDaysViewFields));
        if(d<=canchaLimit){
          vm.showNextDay = true;
        }else{
          vm.showNextDay = false;
        }
      }
      
    };

    vm.load = function(){
      var id = $location.search().id; 

      var dayName = days[d.getDay()];
      var dayNumber = d.getDate();

      var monthName = months[d.getMonth()];
      
      vm.day=dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName;

      var params = "?id="+id+"&days="+vm.daycount+"&userId="+$rootScope.user.id;

      //cambiar a cuponeraactividad
      Request.get('canchaactivity/'+params,true,function(response) {
        if(response.status !== 0){
          vm.errorMsg = $translate.instant(response.description);
        }else{
          // $localstorage.setObject('config',response.config);
          // var config  = $localstorage.getObject('config');
          // if(config.reservationEnabled===0){
          //   vm.reservation_enabled = false;
          //   vm.notice_title = config.noticeAlertTitle;
          //   vm.notice_text = config.noticeAlertText;
          // }

          vm.activities = response.description;
          
          var categories = vm.activities.map(function(item) {
            return item.CategoryName;
          });
          var filter_act = vm.activities.map(function(item) {
            return item.name;
          });
          
          vm.maxActiveResFlag = response.maxActiveReservation;
          if (vm.maxActiveResFlag>0){
            vm.dayStartRes = response.maxActiveReservationStartDay;
          }else{
            vm.dayStartRes = 0;
          }
          vm.categories = categories.filter(unique);
          vm.categories.sort();
          vm.filter_act = filter_act.filter(unique);
          vm.filter_act.sort();
          vm.no_activities = (vm.activities.length === 0)?true:false;
          
          vm.show_selectCat = (vm.categories.length>1)?true:false;
          vm.show_selectAct = (vm.filter_act.length>1)?true:false;
          
        }
      });
    };

    vm.reservation = function (id,at,day,desc) {
      var data = {"usr":id,"at":at,"day":day,"description":desc};

      Request.post('canchareservation/',true,data,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.success"));
          $window.open(EnvConfig.URL_PAGOSCANCHA+"product/?type=CANCHA&id="+response.description.id,"_self");
        }
      });
    };

    vm.cancelReservation = function (id) {
      Request.delete('canchareservation/?id='+id+'&cId='+vm.infocuponera.id,true,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
          // toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.load();
          vm.sendNotification(id,'cancel');
        }
      });
    };

    vm.load();

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirmareservacancha.html',
        controller: 'ModalCanchaReservaController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          dependents: function () {
            return vm.dependents;
          },
          action: function () {
            return vm.action;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            switch (vm.action) {
              case 'PARENT':
                vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'DEPENDENT':
                vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'CANCEL':
                vm.cancelReservation(vm.activity.reservationId);
              break;
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.doReservation = function(action,activity){
      vm.activity = activity;
      vm.activity.guests = "";
      vm.action = action;
      vm.at = vm.activity.id;
      
      if(!$rootScope.user.id){
        vm.openSignup();
      }else{
        vm.id = $rootScope.user.id;
        vm.moredays = vm.daycount;
        if(action === 'PARENT' || action === 'DEPENDENT'){
          vm.title = $translate.instant("reserve.modal_title_confirm");
        }else if(action === 'CANCEL'){
          vm.title = $translate.instant("reserve.modal_title_cancel");
        }
        
        vm.open();
      }

    };

    vm.showOccupancy = function(max,total){
      if(max === -1){
        return true;
      }
      var occupancy = max-total;
      if(occupancy<1){
        return false;
      }
      return true;
    };

    vm.showInfo = function(activity){
      vm.activity = activity;
      vm.openInfo();
    };

    vm.openInfo = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'showInfo.html',
        controller: 'ModalInfoController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.addToWaiting = function(activity){
      vm.activity = activity;
      vm.title = $translate.instant("reserve.add_to_waitinglist");
      vm.openWaitingList();
    };

    vm.openWaitingList = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'waitingreservation.html',
        controller: 'ModalWaitingController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          user: function () {
            return $rootScope.user;
          },
          members: function () {
            return vm.dependents;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.member = result.member;
            if(vm.member && vm.activity){

              var data = {"usr":vm.member,"at":vm.activity.id,"day":vm.daycount};

              Request.post('waitinglist/',true,data,function(response) {
                if(response.status !== 0){
                  toaster.pop('error', "", $translate.instant('api.'+response.description));
                }else{
                  toaster.pop('success', "", $translate.instant("reserve.waitinglist_added"));
                }
              });
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };



    vm.openSignup = function(){
      vm.user = {};
      vm.user.companyId = EnvConfig.COMPANY_ID;
      vm.user.profile_img = "images/Icono_persona.png";
      vm.openSignupForm();
    };

    vm.openSignupForm = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      // size = "lg";
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'signup.html',
        controller: 'ModalSignupFormController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          user: function () {
            return vm.user;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
        if(result.status === 'OK'){
          if(result.action === 'signin'){
            vm.signIn(result.user.email,result.user.password);
          }
          if(result.action === 'signup'){
            vm.signUp(result.user);
          }
        }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.signUp = function (user){
        Request.post('signupcancha',false,user,function(response) {
          if(response.status !== 0){
            toaster.pop('error', "", $translate.instant('api.'+response.description));
          }else{
            toaster.pop('success', "", $translate.instant("signup.success"));
            vm.signIn(user.email, user.password);
          }
        });
    };

    vm.signIn = function (user, pwd){
      var data = {user:user,password:pwd};
      Request.put('signin',false,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
          $localstorage.clear();
        }else{
          $localstorage.set('token',response.token);
          $localstorage.setObject('user',response.description);
          $localstorage.setObject('dependents',response.dependents);
          $localstorage.setObject('user_files',response.user_files);
          $rootScope.user = response.description;
          $rootScope.user.profile_img = "images/Icono_persona.png";
          $rootScope.user.nameToShow = $rootScope.user.firstName.split(" ");
          $rootScope.user.nameToShow = $rootScope.user.nameToShow[0];
          $rootScope.reservasButton = ($rootScope.user.userAccess==='CANCHA')?"/homecanchas":"/home";
          vm.user = $rootScope.user;
          $translate.use(response.description.lang);
          $localstorage.set('showMsg',(response.configuration.showInitialMsg===1)?true:false);
          $localstorage.setObject('config',response.configuration);
          $localstorage.setObject('messages',response.messages);
          $rootScope.messages = response.messages;
          $rootScope.headLander = true;
          vm.doReservation('PARENT',vm.activity);
        }
      });
    };

  });

  angular.module('ufcw').controller('ModalCanchaReservaController', function ($uibModalInstance,$log,title,day,dependents,action,activity,toaster,$translate,Request) {
    var vm = this;

    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.dependents = dependents;
    vm.action = action;
    vm.guest = [];
    vm.error_guest = [];

    vm.ok = function () {

      try {
        if(vm.action === 'DEPENDENT' && !vm.selDependent){
          throw $translate.instant("reserve.must_select_dependent");
        }
        
        if(vm.guest.length > 0){
          vm.activity.guests = vm.guest.toString();
        }

        if(!vm.activity.reservationId && vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guest.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
            }
          });
        }else{
          $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
        }

      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalWaitingController', function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalInfoController', function ($uibModalInstance,$log,activity,toaster) {
    var vm = this;
    
    vm.activity = activity;
    
    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalSignupFormController', function ($uibModalInstance,$log,user,toaster,$translate) {
    var vm = this;
    
    vm.user = user;
    vm.tipoSocio = "cancha";
    vm.show_signup = false;
    vm.title = $translate.instant("Inicio de sesión");

    function formatDate(num){
      return (num.toString().length===1)?"0"+num:""+num;
    }

    vm.updateTitle = function () {
      vm.title = $translate.instant("Registro de Usuario");
    };

    vm.signin = function () {
      vm.user.email = vm.email;
      vm.user.password = vm.password;
      $uibModalInstance.close({user: vm.user, action:'signin', status: 'OK'});
    };

    vm.signup = function () {
      try {
        vm.user.dob = vm.dob.getFullYear()+"-"+formatDate(vm.dob.getMonth()+1)+"-"+formatDate(vm.dob.getDate());  
        $uibModalInstance.close({user: vm.user, action:'signup', status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirmareservacancha.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'signup.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.user = vm.resolve.user;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').controller('ModalWaitingController', function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });