'use strict';

angular.module('ufcw')
  .controller('ResCanchaController', function ($rootScope,$log,$location,Request,$localstorage,toaster,$translate) {

    var vm = this;    
    var id = $location.search().id; 

    var user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    $rootScope.user = user;    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("Confirmación de Reserva");
   
    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    
    vm.load = function(id){
      Request.get('canchareservation/?id='+id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.reserva = response.description;
          if(vm.reserva.status === "CANCHARESERVED"){
            toaster.pop('success', "", $translate.instant("reserve.success"));
          }
          vm.reserva.cost = parseFloat(vm.reserva.cost);
          vm.reserva.reservationdate = vm.reserva.reservationdate.replace(/-/g, '/');
          var d = new Date(vm.reserva.reservationdate);
          var dayName = days[d.getDay()];
          var dayNumber = d.getDate();
          var monthName = months[d.getMonth()];
          vm.reserva.day = dayName +" "+ dayNumber+ " " + $translate.instant('general.of') + " "+ monthName;
        }
      });
    };

    if(id){
      vm.load(id);
    }

  });