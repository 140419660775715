'use strict';

angular.module('ufcw')
  .controller('MapController', function ($rootScope, $translate) {
    var vm = this;
    vm.errorMsg = null;

    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("");    

  });