'use strict';

angular.module('ufcw')
  .controller('RoutineController', function ($log,Request,$localstorage,$state,$rootScope) {
    var vm = this;
    vm.errorMsg = null;
    vm.selectedActivity = {};
    vm.selectedTarget = {};
    vm.selectedType = {};
    vm.level =0; 
    
    $rootScope.headLander = false;

    var token  = $localstorage.get('token');
    vm.backPath = "/home";
    if(!token){
      vm.backPath = "/signin";
    }

    vm.selectActivity = function(id){
      // $log.log('chino');
      // vm.selectedActivity = {};
      vm.routinesActivities.map(function(item){

        if (item.id===id){
          vm.selectedActivity = item;
          vm.selectedType = {};
          vm.level =0;
        }
        
  
      });
      

    };

    vm.selectTarget = function(id){
      
      // vm.selectedActivity = {};
      vm.routinesTargets.map(function(item){

        if (item.id===id){
          // vm.selectedActivity = item;
          vm.selectedTarget = item;
          vm.selectedType = {};
          vm.level =0;
        }
        
  
      });
      

    };


    vm.selectTypes = function(id){
      // $log.log(vm.selectedActivity.types,'chino2');

      //  var hax= vm.selectedActivity.types;
      var hax= vm.selectedTarget.types;

      //  $log.log(hax,'chino2');

      hax.map(function(item){

        if (item.id===id){
          vm.selectedType = item;
          vm.level =0;
        }
        
  
      });
      

    };

   
    

    Request.get('routine/?type=1',true,function(response) {
      // vm.routinesActivities = response.description;  
      vm.routinesTargets = response.description;  
    });


    vm.showRoutine = function(id){

      // var activity = vm.selectedActivity.id;
      var target = vm.selectedTarget.id;
      var type = vm.selectedType.id;
      vm.level = id;

      // var data = {"typeId":type,"level":vm.level,"activity":activity};
      var data = {"typeId":type,"level":vm.level,"target":target};
      Request.post('routine/',true,data,function(response) {
        vm.routines = response.description;
       });
      // Request.get('routine/?type=2&activity='+activity+"&typeId="+type+"&level="+level,true,function(response) {
      //   vm.routines = response.description;  
      // });
  
      

    };
    
    //get activities for user category
    // Request.get('nooauthactivity/get/?type=cat',true,function(response) {
    //     vm.activities = response.description;
    //     vm.activities.map(function(item){
    //       item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
    //     });
    // });

    // Request.get('routine/',true,function(response) {
    //   vm.allroutines = response.description;  
    // });
      
    vm.UNIT_MAP = {MIN: "Minutos",SEC: "Segundos",RPT: "Repeticiones",KG: "Kilos",MTS: "Metros",KM: "Kilometros"};

  });

  



