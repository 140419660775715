angular.module('ufcw')

.constant('EnvConfig', {
  "URL_API"			: "https://api-agenda.clubtabare.com.uy/",
  "URL_IMAGES"  : "https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/tabare/",
  "URL_FILES"  : "https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/tabare/",
  "COMPANY_ID"  : "1",
  "SIGNIN_ENABLED":true,
  "RESERVATION_ENABLED":true,
  "footer_app_name":"Club Tabaré",
  "footer_email":"info@clubtabare.com.uy",
  "footer_facebook":"https://www.facebook.com/clubatleticotabare",
  "footer_twitter":"https://twitter.com/clubtabare",
  "footer_instagram":"https://www.instagram.com/clubtabare/",
  "footer_youtube":"",
  "show_aviso_covid":false,
  "show_reglas":false,
  "signup_nrosocio":false,
  "signup_dob":false,
  "projectname":"tabare",
  "show_payment_expiration": false,
  "show_carnet_expiration": true
});