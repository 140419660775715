'use strict';

angular.module('ufcw')
  .controller('HomeCanchaController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;

    vm.reservation_enabled = true;
    
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');
    vm.show_msg = $localstorage.get('showMsg');
    
    //redirect signin if show cachas config is disabled
    vm.showCanchas = EnvConfig.show_canchas;
    if(!vm.showCanchas){
      $state.go('signin');
    }

    if(user){
      user.profile_img = "images/Icono_persona.png";
      user.nameToShow = user.firstName.split(" ");
      user.nameToShow = user.nameToShow[0];
      $rootScope.user = user;
      $rootScope.headLander = true;
    }
    
    $rootScope.titulopagina = $translate.instant("general.activities");

    //get activities for user category
    Request.get('canchaactivity',false,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
          item.description = (item.description)?angular.fromJson(item.description):item.description;
        });
      }
    });


  });